// Tabs.js
import React, { useState, useEffect } from 'react';
import Tab from './Tab';
import TabContent from './TabContent';
import axios from 'axios';
import Home2 from '../pages/Tab2';
import Home3 from '../pages/Tab3';
import Home4 from '../pages/Tab4';
import { Routes, Route, useParams } from 'react-router-dom';

const Tabs = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [clienteNombre, setClienteNombre] = useState('');

  // Obtén el valor de cli de la URL
  const { cli } = useParams();

  useEffect(() => {
    // Realiza una solicitud al servidor para obtener el nombre del cliente
    axios.get(`https://apiautomarcobi.automarco.cl:3004/api/datosCliente?ven=${cli}`)
      .then((response) => {
        setClienteNombre(response.data.clienteNombre);
      })
      .catch((error) => {
        console.error('Error al obtener el nombre del cliente:', error);
      });
  }, [cli]);  // Asegúrate de ejecutar la solicitud cada vez que cambia "cli"


  const handleTabClick = (index) => {
    if (index !== 1 && index !== 2 && index !== 3) {
    setActiveTab(index);
    }
  };

  const tabsData = [
    { label: 'Productos Frecuentes', content: <TabContent cli={cli} /> },
    { label: 'Home2', content: <Home2 cli={cli} /> },
    { label: 'Home3', content: <Home3 cli={cli} /> },
    { label: 'Home4', content: <Home4 cli={cli} /> },
    // Agrega más pestañas según sea necesario
  ];
  
  return (
    
    <div>
      <div>
        <h2>Cliente: {clienteNombre}</h2>
      </div>
      <ul className="nav nav-tabs">
        {tabsData.map((tab, index) => (
          <Tab
            key={index}
            label={tab.label}
            isActive={index === activeTab}
            onClick={() => handleTabClick(index)}
            disabled={index === 1 || index === 2 || index === 3}
          />
        ))}
      </ul>
      <div className="tab-content">
        {/* Solo necesitas una ruta principal para los tabs */}
        <Routes>
          <Route path="/" element={tabsData[activeTab].content} />
        </Routes>
      </div>
    </div>
  );
};

export default Tabs;
