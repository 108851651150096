// App.js
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './pages/Home';
import Tabs from './tabs/Tabs';
//import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/:cli//*" element={<Tabs />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
