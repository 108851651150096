import React, {useState, useEffect} from 'react';
import {Link, useParams} from 'react-router-dom';
//import "./Home.css";
import {toast} from 'react-toastify';
import axios from 'axios';
import Table from 'react-bootstrap/Table'
import Accordion from 'react-bootstrap/Accordion'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Pagination from 'react-bootstrap/Pagination';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
//import Aplicaciones from './Aplicaciones';
import { useRef } from 'react';
import { useLocation } from 'react-router-dom'


const TabContent = ({cli}) => {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 12;
    //console.log( "HOLA" + cli );
    const { ven } = cli;
    //const { ven } = useParams();
    const [datos, setDatos] = useState([
        { id: 1, nombre: 'Registro 1' },
        { id: 2, nombre: 'Registro 2' },
        // ...otros registros
      ]);
    
    //console.log( { ven } );
  const [data, setData] = useState([]);
  const [modalAbierta, setModalAbierta] = useState(false);
  const [datosModal, setDatosModal] = useState([]);
  const [prodidModal, setProdidModal] = useState(null);
  const [carroItems, setCarroItems] = useState([]);
  const [cantidadCarro, setCantidadCarro] = useState('');

  const location = useLocation();
  const productos = new URLSearchParams(location.search).get("productos");

  const [productosHabilitados, setProductosHabilitados] = useState([new Set]);

  useEffect(() => {
    // Divide la cadena de productos en un array y establece el estado
    setProductosHabilitados(new Set(productos ? productos.split(',') : []));
  }, [productos]);

  //const [data2, setData2] = useState([]);

  //const 

  const loadData = async () => {
        let buscar = document.getElementById('search').value;

        // let buscar = document.getElementById('types_input').value;
        await axios.get("https://apiautomarcobi.automarco.cl:3004/api/frecuentes", {
            params: {
              ven:  cli ,
              bus: buscar, 
            }
        }).then((response) => {
            
            setData(response.data);
         })
     
        
  };

  const loadModalData = async (prodid) => {
    try {
      const response = await axios.get("https://apiautomarcobi.automarco.cl:3004/api/aplicaciones", {
        params: {
            prodid: prodid
        }
      });
      const modalData = response.data;
      
      // Aquí puedes utilizar modalData para llenar tu modal con la información necesaria
      // Por ejemplo, podrías actualizar el estado del componente que controla la modal
      // o llamar a alguna función que maneje la presentación de la modal con la información cargada.
      setDatosModal(modalData);
      setProdidModal(prodid);
      //console.log(modalData);
    } catch (error) {
      console.error('Error al cargar datos de la modal:', error);
    }
  };
  
  const effectRan = useRef(false);

  useEffect(() => {
    const input = document.getElementById('search');
    if (input) {
        input.setAttribute('autocomplete', 'off');
    }
    if (!effectRan.current) {
    loadData();
    }
    return () => {effectRan.current = true};
  },[]);

  function busqueda() {
    loadData();
  }

  const formatoNumero = (numero) => {
    return numero.toLocaleString('es-ES');
  };

  const abrirModal = (prodid) => {
    // Aquí puedes agregar la lógica para obtener los datos de MySQL para el registro específico
    // Puedes usar una función asíncrona y await para hacer la consulta a la base de datos

    // Ejemplo simulado de una función asíncrona ficticia para obtener datos de MySQL
    loadModalData(prodid).then(() => {
        setModalAbierta(true);
      });
    
};

  const cerrarModal = () => {
    setModalAbierta(false);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const obtenerProdId = (item) => {
    return item.prod_id;
  };

  const handleCantidadChange = (prodId, e) => {
    let nuevaCantidad = e.target.value;
  
    // Validar que la cantidad sea un número y mayor o igual a 0
    const cantidadNumerica = parseInt(nuevaCantidad, 10);
    if (/^\d*$/.test(nuevaCantidad) && cantidadNumerica >= 0) {
      // Evitar que se ingrese un cero al comienzo
      if (nuevaCantidad.length === 1 && nuevaCantidad === "0") {
        nuevaCantidad = "";
      }
  
      setCantidadCarro((prevCantidad) => ({
        ...prevCantidad,
        [prodId]: nuevaCantidad
      }));
    } else {
      // Si la validación falla, o se ingresó un valor no válido, puedes mostrar un mensaje de error o realizar otra acción
      console.error('Ingrese un número válido mayor o igual a 0');
    }
  };
  
  
  const handleKeyDown = (e, prodId) => {
    // Permitir eliminar el último dígito con la tecla "Backspace"
    if (e.key === 'Backspace') {
      setCantidadCarro((prevCantidad) => ({
        ...prevCantidad,
        [prodId]: (prevCantidad[prodId] || '').slice(0, -1)
      }));
    }
  };

  const agregarAlCarro = () => {
    const itemsSeleccionados = data.map(item => {
      const prodId = obtenerProdId(item);
      const cantidad = cantidadCarro[prodId] && cantidadCarro[prodId].trim() !== '' ? cantidadCarro[prodId] : '0';
      const precio = item.prod_precio; 
      return { prodId, cantidad, precio };
    });

    const itemsValidos = itemsSeleccionados.filter(item => item.cantidad !== '0');

    if (itemsValidos.length > 0) {
      console.log('Agregado al carro:', itemsValidos);
      setCantidadCarro({});
      setCarroItems([...carroItems, ...itemsValidos]);
      if (typeof window.Android !== 'undefined') {
        window.Android.recibirDatos(JSON.stringify(itemsValidos));
      }
      //Android.recibirDatos(JSON.stringify(itemsValidos));
    } else {
      toast.error('Por favor, ingresa una cantidad válida.');
    }
  };
  // Calcula el índice de inicio y fin para los elementos de la página actual
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Filtra los elementos a mostrar en la página actual
  const currentItems = data.slice(startIndex, endIndex);

  // Resto del código

  return (
    
    <div style={{marginTop: "100px", marginLeft: "10px", marginRight: "0px"}}>
        <div className='form-inline'>
            <div id="field_wrapper" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                <div className="my-class-form-control-group">
                    <Form.Control
                        type="search"
                        id="search"
                        autoComplete="off"  // Desactivar autocompletado
                        style={{ width: "300px" }}
                    />
                </div>
                <Button onClick={busqueda} style={{ marginLeft: '10px' }}>Buscar</Button>
            </div>
        </div>
        <br></br>
        <div className="my-class-form-control-group">
        <Table size="sm" >
            <thead>
                <tr>
                    <th>Frecuentes</th>
                    <th>Cod. Fabrica</th>
                    <th>Linea</th>
                    <th>Marca</th>
                    <th>Precio</th>
                    <th>Stock</th>
                    <th>Aplicaciones</th>
                    <th>Unidades</th>
                </tr>
            </thead>
            <tbody>
        {currentItems.map((item, index) => {
            //const [data2, setData2] = useState([]);
            
            return (
                    
                            
                <tr style={{textAlign: "left"}} key={item.prod_id}>
                    <td style={{borderBottomWidth: "0px",width: "12%", whiteSpace: 'nowrap'}}>{item.prod_id}</td>
                    <td style={{borderBottomWidth: "0px",width: "12%", whiteSpace: 'nowrap'}}>{item.id_prov}</td>
                    <td style={{borderBottomWidth: "0px",width: "12%", whiteSpace: 'nowrap'}}> {item.cla_nombre}</td>
                    <td style={{borderBottomWidth: "0px",width: "12%", whiteSpace: 'nowrap'}}> {item.marca_nombre}</td>
                    <td style={{borderBottomWidth: "0px",width: "12%", whiteSpace: 'nowrap'}}> ${formatoNumero(item.prod_precio)}</td>
                    <td style={{borderBottomWidth: "0px",width: "12%", whiteSpace: 'nowrap'}}> {formatoNumero(item.prod_stock)}</td>
                    <td style={{borderBottomWidth: "0px",width: "12%", whiteSpace: 'nowrap'}}> <Button onClick={() => abrirModal(item.prod_id)}>Aplicaciones</Button></td>
                    <td style={{borderBottomWidth: "0px",width: "16%", whiteSpace: 'nowrap'}}> {item.multiplo ? (
                    <InputGroup>
                        {/*<!--FormControl
                            type="text"
                            value={cantidadCarro[item.prod_id] || ''}
                            onChange={(e) => handleCantidadChange(item.prod_id, e)}
                            onKeyDown={(e) => handleKeyDown(e, item.prod_id)}
                            style={{ width: "60px" }}
                            inputMode="numeric"
                            pattern="[0-9]*"
                            disabled={productosHabilitados.has(item.prod_id)}
                    /-->*/}
                        <InputGroup.Text id="basic-addon2" >
                            <select 
                            value={cantidadCarro[item.prod_id] || ''}
                            onChange={(e) => handleCantidadChange(item.prod_id, e)}
                            disabled={productosHabilitados.has(item.prod_id)}>
                                {[...Array(100 / item.multiplo)].map((_, i) => (
                                    <option key={i + 1}>{item.multiplo * (i + 1)}</option>
                                ))}
                            </select>
                        </InputGroup.Text>
                    </InputGroup>
                ) : (
                    <FormControl
                        type="text"
                        value={cantidadCarro[item.prod_id] || ''}
                        onChange={(e) => handleCantidadChange(item.prod_id, e)}
                        onKeyDown={(e) => handleKeyDown(e, item.prod_id)}
                        style={{ width: "60px" }}
                        inputMode="numeric"
                        pattern="[0-9]*"
                        disabled={productosHabilitados.has(item.prod_id)}
                    />
                )}</td>
                </tr>
            );
        })}
    
        </tbody>
        </Table>
        <Modal show={modalAbierta} onHide={cerrarModal}>
        <Modal.Header closeButton>
          <Modal.Title>{`Aplicaciones - Producto ${prodidModal || ''}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Table size="sm" >
        <thead>
            <tr>
               <th>Marca</th>
			   <th>Modelo</th>
			   <th>Cilindrada</th>
			   <th>Año Inicio</th>
			   <th>Año Término</th>

            
            </tr>
        </thead>
        <tbody>
        {datosModal.map((item, index) => {
        return (
                <tr style={{textAlign: "left"}} key={item.pm_id}>
                    <td style={{borderBottomWidth: "0px",width: "12%"}}>{item.marca_nombre}</td>
                    <td style={{borderBottomWidth: "0px",width: "12%"}}>{item.mod_id}</td>
                    <td style={{borderBottomWidth: "0px",width: "12%"}}> {item.cilindrada}</td>
                    <td style={{borderBottomWidth: "0px",width: "12%"}}> {item.inicio}</td>
                    <td style={{borderBottomWidth: "0px",width: "12%"}}> {item.fin}</td>
                </tr>
                    );
        })}
        </tbody>
        </Table>
      
          
          {/* Agrega más campos según tus datos personalizados */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={cerrarModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
        </div>
        <Pagination>
        <Pagination.Prev
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
        />
        {Array.from({ length: Math.ceil(data.length / itemsPerPage) }, (_, index) => (
          <Pagination.Item
            key={index + 1}
            active={index + 1 === currentPage}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next
          disabled={currentPage === Math.ceil(data.length / itemsPerPage)}
          onClick={() => handlePageChange(currentPage + 1)}
        />
      </Pagination>
      <Button variant="primary" onClick={agregarAlCarro} style={{ float: 'right', marginRight: '180px' }}>
        Agregar al Carro
      </Button>
    </div>
    
  )
}

export default TabContent;