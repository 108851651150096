import React, {useState, useEffect} from 'react';
import {Link, useParams} from 'react-router-dom';
//import "./Home.css";
import {toast} from 'react-toastify';
import axios from 'axios';
import Table from 'react-bootstrap/Table'
import Accordion from 'react-bootstrap/Accordion'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
//import Aplicaciones from './Aplicaciones';
import { useRef } from 'react';
import {Routes, Route} from 'react-router-dom'


const Tab3 = ({cli}) => {
    //console.log( "HOLA" + cli );
    const { ven } = cli;
    //const { ven } = useParams();
    const [datos, setDatos] = useState([
        { id: 1, nombre: 'Registro 1' },
        { id: 2, nombre: 'Registro 2' },
        // ...otros registros
      ]);
    
    //console.log( { ven } );
  const [data, setData] = useState([]);
  const [modalAbierta, setModalAbierta] = useState(false);
  const [datosModal, setDatosModal] = useState([]);
  const [prodidModal, setProdidModal] = useState(null);

  //const [data2, setData2] = useState([]);

  //const 

  const loadData = async () => {
        let buscar = document.getElementById('search').value;

        // let buscar = document.getElementById('types_input').value;
        await axios.get("https://apiautomarcobi.automarco.cl:3004/api/potenciales/", {
            params: {
              ven:  ven ,
              bus: buscar, 
            }
        }).then((response) => {
            
            setData(response.data);
         })
     
        
  };

  const loadModalData = async (prodid) => {
    try {
      const response = await axios.get("http://localhost:3004/api/aplicaciones", {
        params: {
            prodid: prodid
        }
      });
      const modalData = response.data;
      
      // Aquí puedes utilizar modalData para llenar tu modal con la información necesaria
      // Por ejemplo, podrías actualizar el estado del componente que controla la modal
      // o llamar a alguna función que maneje la presentación de la modal con la información cargada.
      setDatosModal(modalData);
      setProdidModal(prodid);
      //console.log(modalData);
    } catch (error) {
      console.error('Error al cargar datos de la modal:', error);
    }
  };
  
  const effectRan = useRef(false);
  useEffect(() => {
    
    if (!effectRan.current) {
    loadData();
    }
    return () => {effectRan.current = true};
  },[]);

  function busqueda() {
    loadData();
  }

  const formatoNumero = (numero) => {
    return numero.toLocaleString('es-ES');
  };

  const abrirModal = (prodid) => {
    // Aquí puedes agregar la lógica para obtener los datos de MySQL para el registro específico
    // Puedes usar una función asíncrona y await para hacer la consulta a la base de datos

    // Ejemplo simulado de una función asíncrona ficticia para obtener datos de MySQL
    loadModalData(prodid).then(() => {
        setModalAbierta(true);
      });
    
};

  const cerrarModal = () => {
    setModalAbierta(false);
  };

  return (
    <div style={{marginTop: "150px", marginLeft: "80px", marginRight: "80px"}}>
        <div className='form-inline'>
            <div id="field_wrapper" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                <div className="my-class-form-control-group">
                    <Form.Control
                        type="text"
                        id="search"
                        autoComplete="off"  // Desactivar autocompletado
                        style={{ width: "300px" }}
                    />
                </div>
                <Button onClick={busqueda} style={{ marginLeft: '10px' }}>Buscar</Button>
            </div>
        </div>
        <br></br>
        <div className="my-class-form-control-group">
        <Table size="sm" >
            <thead>
                <tr>
                    <th>Frecuentes</th>
                    <th>Cod. Fabrica</th>
                    <th>Linea</th>
                    <th>Marca</th>
                    <th>Precio</th>
                    <th>Stock</th>
                    <th>Aplicaciones</th>
                    <th>Unidades</th>
                </tr>
            </thead>
            <tbody>
        {data.map((item, index) => {
            //const [data2, setData2] = useState([]);
            
            return (
                    
                            
                <tr style={{textAlign: "left"}} key={item.prod_id}>
                    <td style={{borderBottomWidth: "0px",width: "12%"}}>{item.prod_id}</td>
                    <td style={{borderBottomWidth: "0px",width: "12%"}}>{item.id_prov}</td>
                    <td style={{borderBottomWidth: "0px",width: "12%"}}> {item.cla_nombre}</td>
                    <td style={{borderBottomWidth: "0px",width: "12%"}}> {item.marca_nombre}</td>
                    <td style={{borderBottomWidth: "0px",width: "12%"}}> ${formatoNumero(item.prod_precio)}</td>
                    <td style={{borderBottomWidth: "0px",width: "12%"}}> {formatoNumero(item.prod_stock)}</td>
                    <td style={{borderBottomWidth: "0px",width: "12%"}}> <Button onClick={() => abrirModal(item.prod_id)}>Aplicaciones</Button></td>
                    <td style={{borderBottomWidth: "0px",width: "16%"}}> <Form.Control type="text" defaultValue =''  style={{ width: "60px" }}/></td>
                </tr>
            );
        })}
    
        </tbody>
        </Table>
        <Modal show={modalAbierta} onHide={cerrarModal}>
        <Modal.Header closeButton>
          <Modal.Title>{`Aplicaciones - Producto ${prodidModal || ''}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Table size="sm" >
        <thead>
            <tr>
               <th>Marca</th>
			   <th>Modelo</th>
			   <th>Cilindrada</th>
			   <th>Posición</th>
			   <th>Año Inicio</th>
			   <th>Año Término</th>

            
            </tr>
        </thead>
        <tbody>
        {datosModal.map((item, index) => {
        return (
                <tr style={{textAlign: "left"}} key={item.pm_id}>
                    <td style={{borderBottomWidth: "0px",width: "12%"}}>{item.marca_nombre}</td>
                    <td style={{borderBottomWidth: "0px",width: "12%"}}>{item.mod_id}</td>
                    <td style={{borderBottomWidth: "0px",width: "12%"}}> {item.cilindrada}</td>
                    <td style={{borderBottomWidth: "0px",width: "12%"}}> {item.ubi_nombre}</td>
                    <td style={{borderBottomWidth: "0px",width: "12%"}}> {item.inicio}</td>
                    <td style={{borderBottomWidth: "0px",width: "12%"}}> {item.fin}</td>
                </tr>
                    );
        })}
        </tbody>
        </Table>
      
          
          {/* Agrega más campos según tus datos personalizados */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={cerrarModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
        </div>
    </div>
    
  )
}

export default Tab3;